import { ApiService } from 'src/app/service/api.service';

import { AuthenticationService } from '../service/authentication.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate {
  tenant: any;

  responseData: any;
  roleAcess: any;
  //users: Array<{name: string, empoloyeeID: number}> = [];
  constructor(
    private auth: AuthenticationService,
    private apiService: ApiService,
    private router: Router,
    private alertCtrl: AlertController,
    public storage: Storage
  ) {}
  canLoad(): Observable<boolean> {
    return this.auth.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        console.log(isAuthenticated);

        if (isAuthenticated) {
          return true;
        } else {
          this.router.navigate(['/login']);

          return false;
        }
      })
    );
  }
  canActivate(route: ActivatedRouteSnapshot) {
    return this.auth.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {

        if (isAuthenticated) {
          const userRole = this.auth.getUser();
         // console.log(userRole);

       //   console.log(route.data.profile);

          // if (route.data.profile && route.data.role.indexOf(userRole) === -1) {
          //   this.router.navigate(['/home']);
          //   return false;
          // }
          return true;
        } else {
          this.router.navigate(['/login']);

          return false;
        }



      })
    );

  }


  async showAlert() {
    let alert = await this.alertCtrl.create({
      header: 'Não Autorizado',
      message: 'Acesso Restrito',
      buttons: [
        {
          text: 'Sim',
          handler: () => {
            this.router.navigateByUrl('/home', { replaceUrl: true });
          },
        },
      ],
    });
    alert.present();
  }
}
