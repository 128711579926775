
import { StorageService } from 'src/app/service/storage.service';
import { ModalController, AlertController } from '@ionic/angular';

import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FCM } from '@capacitor-community/fcm';


import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  token: any;
  invoice: any;
userDetails: any;
response : any;

  constructor(
    public modalController: ModalController,
    public router: Router,
    public storageService: StorageService,
    public alertController: AlertController,
    private apiService : ApiService

  ) {


  }

  initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    } else {
      //  this.registerFire();
    }


  }
  public registerTopic(profile) {
    if (Capacitor.getPlatform() !== 'web') {
      FCM.subscribeTo({ topic: profile })
      //.then((r) => 
     // alert("registrou canal "+profile)
      // )
      .catch((err) => console.log(err));
    } else {
      //  this.registerFire();
    
    }
   


  }
  public UnregisterTopic(profile) {
    if (Capacitor.getPlatform() !== 'web') {
      FCM.unsubscribeFrom({ topic: profile })
     // .then((r) => 
      //alert("REMOVEU "+profile) 
     // )
      .catch((err) => console.log(err));
    } else {
      //  this.registerFire();
    
    }
   


  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        PushNotifications.register();

      } else {
        // If permission is not granted
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
       

       this.registerToken(token.value);
      }
    );
    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        this.Alert(JSON.stringify(error), "'Push registration success");
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );


    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
   //     alert('Notificacao Recebida no app received: ' + JSON.stringify(notification));
         this.Alert(notification.body, notification.title, notification.data);
      }
    );



    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (item: ActionPerformed) => {
       // alert('Push action performed: ' + JSON.stringify(notification));
               this.Alert(item.notification.body, item.notification.title, item.notification.data);
      }
    );


  }

async registerToken(item){
 this.storageService.setObject('token', item);
console.log("Token Gerada :",item);
  
  this.token = item;
  this.userDetails = await this.storageService.getObject(environment.name);
 // console.log(this.userDetails);
 // this.Alert(this.token, 'Token',);
if(this.userDetails){
  if(this.token === this.userDetails.token){
    // this.Alert('NÁO ATUALIZAR', 'AVISO')
   }else{
     //this.Alert('Atualizar', 'alerta');
     this.userDetails.token = this.token;
     this.updateProfile(this.userDetails);
   }
}

  
  
  //this.Alert(JSON.stringify(this.userDetails), `Registro token`)
}
updateProfile(item) {
console.log('update: ',item);

  this.apiService.postTenant(item, 'person/'+this.userDetails.idPerson).then(
    (result) => {
      console.log(result);

      this.response = result;
      if(this.response){
        this.storageService.setObject('kam', item);
      }
   
      this.Alert(this.response, "Aviso!!!");

      
      

      
    },
    (err) => { }
  );

  
//  return this.token.asObservable();
}


  async openDetails(item) {
    this.router.navigateByUrl('/notifications', { replaceUrl: true });

     const modal = await this.modalController.create({
       component: '',
       cssClass: 'cart-modal',
       componentProps: { item },
     });
     modal.onDidDismiss().then((dataReturned) => { });
     return await modal.present();
  }

  async Alert(texto = null, titulo = null, data = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: titulo,
      message: texto,
      buttons: [
        {
          text: 'ok',
          handler: () => {
            // if (data.tipo == 'invoice') {
            //   this.openDetails(data.invoice);
            // } else {


            // }
          },
        },
      ],
    });

    await alert.present();
  }
}
