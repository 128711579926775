import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { Storage } from '@ionic/storage-angular';
import { LoaderService } from 'src/app/service/loader.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Capacitor } from '@capacitor/core';
import { ApiService } from 'src/app/service/api.service';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  OAuthProvider,
  getAdditionalUserInfo,
  updateProfile,
  updateCurrentUser,
  GoogleAuthProvider,
} from '@angular/fire/auth';
import {
  SignInWithApple,
  AppleSignInResponse,
  AppleSignInErrorResponse,
  ASAuthorizationAppleIDRequest,
} from '@ionic-native/sign-in-with-apple/ngx';

import { AlertController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import 'firebase/auth';

import { GooglePlus } from '@ionic-native/google-plus/ngx';


import { getFirestore, collection, doc, getDoc, DocumentData, setDoc } from 'firebase/firestore';


@Injectable({
  providedIn: 'root',
})
export class AuthLoginService {
  public user = null;

  userisloggedin: boolean;
  finishedloading: boolean;
  useremail: string;
  userid: string;

  public isGoogleLogin = false;
  constructor(
    private platform: Platform,
    public apiService: ApiService,
    public alertController: AlertController,
    private googlePlus: GooglePlus,
    private router: Router,
    public ionloader: LoaderService,
    public authenticationService: AuthenticationService,
    private fireAuth: AngularFireAuth,
    private signInWithApple: SignInWithApple,


    public storage: StorageService
  ) {
    Capacitor.getPlatform();
  }
  async ngOnInit() { }

  async AppleSignIn() {
    if (this.platform.is('cordova')) {
      const responseApple = await this.signInWithApple
        .signin({requestedScopes: [
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
          ],
        })
        .then((res: AppleSignInResponse) => {
          //          console.log(res);
          //          this.checkSignApple(res);
          const provider = new OAuthProvider('apple.com');
          const credential = provider.credential({idToken: res.identityToken,rawNonce: '',
          });
          //console.log(credential);
          const response =  this.onAppleSuccess(credential);
          //alert('Send token to apple for verification: ' + credential);
            


          return response;
        })
        .catch((error: AppleSignInErrorResponse) => {
          // alert(error.code + ' ' + error.localizedDescription);
          console.error(error);
          alert('Error: ' + error);
          return error;
        });
            
        
        console.log(responseApple);
        
        // const result = "Apple";
                  this.setUserFirestore(responseApple)

      return responseApple;
    } else {
      // const provider = new OAuthProvider('apple.com');
      // const responseApple = await this.fireAuth
      //   .signInWithPopup(provider)
      //   .then((result) => {
      //     // This gives you a Google Access Token. You can use it to access the Google API.
      //     this.ionloader.hideLoader();
      //     // The signed-in user info.
      //  //   console.log(result.credential);
      //     this.setUserFirestore(result.user)
      //     return result.user;
      //   })
      //   .catch((error) => {
      //     // Handle Errors here.
      //     const errorCode = error.code;
      //     const errorMessage = error.message;

      //     const credential = GoogleAuthProvider.credentialFromError(error);
      //     return error;
      //     // ...
      //   });

      // //const result = await this.checkProfile(response);

      // return responseApple;
    }
  }

  // async checkSignApple(res) {
  //   const fullName = res.fullName;

  //   if (fullName === '') {
  //     // console.log(res.email);
  //   } else {
  //     // console.log(res.fullName);
  //     // console.log(res);
  //     this.storage.set('appleUser', res);

  //     const appleUser = this.storage.getObject('appleUser');
  //     console.log(appleUser);

  //     // const displayName =
  //     //   res.fullName.givenName + '  ' + res.fullName.familyName;
  //     // this.updateDisplayName(displayName);
  //   }
  // }
  // async updateDisplayName(item) {
  //   const auth = getAuth();
  //   const user = auth.currentUser;

  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is signed in, see docs for a list of available properties
  //       // https://firebase.google.com/docs/reference/js/firebase.User
  //       const uid = user.uid;

  //       updateProfile(user, {
  //         displayName: item,
  //       })
  //         .then(() => {
  //           console.log('Nome Atualizado');
  //           // ...
  //         })
  //         .catch((error) => {
  //           // An error occurred
  //           // ...
  //         });
  //       // ...
  //     } else {
  //       // User is signed out
  //       // ...
  //     }
  //   });
  // }

  async onAppleSuccess(credential) {
    const response = await this.fireAuth
      .signInWithCredential(credential)
      .then((res) => {
        return res.user;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  }

  async GoogleAuth() {
    const provider = new GoogleAuthProvider();

    let params: any;

    if (this.platform.is('cordova')) {
      if (this.platform.is('android')) {
        params = {
          webClientId: "332110140018-8pviinhlcca971b934trn6gvb5vo0ina.apps.googleusercontent.com",
          offline: true,
        };
      } else {
        params = {};
      }

    
      const resultgoogle = await this.googlePlus.login(params).then((result) => {
        const { idToken, accessToken } = result;
        
        const response = this.onLoginSuccess(idToken, accessToken);
      //    this.Alert(response)
        return response;
      })
      .catch((err) => {
        // alert('error:' + JSON.stringify(err));
        
        this.Alert(err)
        return err;
      });
          
      this.setUserFirestore(resultgoogle);
      return resultgoogle
    } else {
      this.ionloader.presentLoader();
      try {
        const result = await this.fireAuth.signInWithPopup(provider);

        this.ionloader.hideLoader();
        const user = result.user;
        //console.log(user);
        
this.setUserFirestore(result.user)

        return result.user;
      } catch (error) {
        console.error('Erro ao autenticar:', error);
        return error;
      }
    }
  }


  async onLoginSuccess(accessToken, accessSecret) {
    const credential = accessSecret
      ? GoogleAuthProvider.credential(accessToken, accessSecret)
      : GoogleAuthProvider.credential(accessToken);

    const response = await this.fireAuth
      .signInWithCredential(credential)
      .then((sucesss) => {
        this.ionloader.hideLoader();
        this.isGoogleLogin = true;
        this.user = sucesss.user;
        return this.user;
      })
      .catch((error) => {
        this.ionloader.hideLoader();
      });

    return response;
  }

  onLoginError(err) {
    console.log(err);
  }

  async setUserFirestore(item) {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', item.uid);
    const userDocSnap = await getDoc(userDocRef);

    // Supondo que result.user seja um objeto UserImpl
    const detail = {
      uid: item.uid,
      email: item.email,
      emailVerified: item.emailVerified,
      displayName: item.displayName,
      isAnonymous: item.isAnonymous,
      photoURL: item.photoURL,
      providerData: item.providerData,

    }
    if (!userDocSnap.exists()) {
      // Se o documento não existir no Firestore, você pode cadastrá-lo aqui
      await setDoc(userDocRef, { detail });
    } else {
      
    }
   // this.updatePerfil(item.displayName);

  }

  async getUserID(uid){
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', uid);
    const userDocSnap = await getDoc(userDocRef);
    if (!userDocSnap.exists()) {
      // Se o documento não existir no Firestore, você pode cadastrá-lo aqui
      
    } else {
      const userData = userDocSnap.data() as DocumentData;
      return userData.detail;
    }

  }

  async updatePerfil(displayName) {
    const auth = getAuth();
    const user = auth.currentUser;
    updateProfile(user, {
      displayName: displayName,
    })
      .then(() => {
        // Profile updated!
        console.log("Profile Atualizado");

        // ...
      })
      .catch((error) => {
        // An error occurred
        // ...
      });
  }
  logout() {
    const logout = this.authenticationService.logout();
    this.fireAuth.signOut();
    this.router.navigateByUrl('/', { replaceUrl: true });
  }


  async Alert(texto = null, header = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: texto,
      buttons: [
        {
          text: 'ok',
          handler: () => {
            //   console.log('Confirm Okay');
          },
        },
      ],
    });
    await alert.present();
  }
}
