import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController,LoadingController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { ApiService } from 'src/app/service/api.service';
import { StorageService } from 'src/app/service/storage.service';
@Component({
  selector: 'app-experimental',
  templateUrl: './experimental.page.html',
  styleUrls: ['./experimental.page.scss'],
})
export class ExperimentalPage implements OnInit {
  experimental: FormGroup;
  submitted = false;
  responseData: any;
  errorAlert: string;
data:any;
  Horarios: any;
  mensagem: any;
  userDetails: any;

  user = {
    name: "".toUpperCase(),
    cpf: "",
    email: '',
    telefone: '',
    data: null,
    horario: ''

  };

  minDate: Date;
  maxDate: Date;
  constructor(
    private modalController: ModalController,
    public toastController: ToastController,
    public fb: FormBuilder,
    
    public authService: ApiService,
    public alertController: AlertController,
    public loadingController: LoadingController,
    private storage: StorageService,
  ) {

    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const day = new Date().getDay();
    console.log(month);

    this.minDate = new Date();
    this.maxDate = new Date(year, month, day + 30);
    this.experimental = this.fb.group({

      name: ['', [Validators.required,]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      cpf:  ['', [Validators.required]],
      date: ['', [Validators.required]],
      idHour: ['', [Validators.required]],



    })

  }

  async ngOnInit() {
    
    const login = await this.storage.getObject('kam');
    this.userDetails = login;


        
    

    this.experimental.get('date').setValue(new Date());
 
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.experimental.valid);
    if (this.experimental.valid) {
      //  console.log('All fields are required.');
      this.gravar(this.experimental.value);

    } else {
      // this.falhaLogin();


    }

  }
  get errorCtr() {
    return this.experimental.controls;
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }
  gravar(dados: any) {


    console.log(dados);
    this.authService.postTenant(dados, 'reservation/box/extra')

      .then((result) => {

console.log(result);

        this.responseData = result;


        if (this.responseData) {
          this.Alert(this.responseData.message);



        } else { }
      }, (err) => {

      });

  }

  async Alert(texto = null, titulo = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: titulo,
      message: texto,
      buttons: [
        {
          text: 'ok',
          handler: () => {
            //   console.log('Confirm Okay');
          //  this.closeModal();
          }
        }
      ]
    });

    await alert.present();
  }


  getHorario(item) {
    
      this.Horarios = null;
  
      const params =
      {
        "date": item,
        "role": this.userDetails.role,
        "idPerson": this.userDetails.idPerson,
        "profile": this.userDetails.profile,
  
      }
  
      console.log(params);
  
      this.authService.postTenant(params, 'schedules').then(
        (result) => {
          this.Horarios = result;
          console.log(result);
  
  
  
        },
        (err) => { }
      );
  
    
  }


  removeSpecialCharacters(event) {
    this.user.telefone = event.target.value.replace('[^A-Za-z0-9]', '');
}
}
