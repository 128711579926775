
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,


//apiUrl : 'http://serverbase:3000/',
apiUrl : 'https://appbox.itmizer.com.br/',
//apiUrl : 'http://192.168.2.141:3000/',

//apiUrl : 'https://application.itmizer.app/',


tenant_id: 3,

username : 'itmizer',
password : '@itmizer#4084',
name : 'kam',

 firebaseConfig: {
  apiKey: "AIzaSyCuv8knLcb1v4wsqtUD58lUo2HK2-iYKQg",
  authDomain: "grgkam-d512d.firebaseapp.com",
  databaseURL: "https://grgkam-d512d-default-rtdb.firebaseio.com",
  projectId: "grgkam-d512d",
  storageBucket: "grgkam-d512d.appspot.com",
  messagingSenderId: "332110140018",
  appId: "1:332110140018:web:faa08021360d27e96d28eb",
  measurementId: "G-8HG8ZPTXT2"
 },

};
export const SERVER_URL = 'http://localhost:8080';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
