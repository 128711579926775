import { StorageService } from './service/storage.service';
import { filter, take, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Platform } from '@ionic/angular';
import { ApiService } from 'src/app/service/api.service';



import { AlertController } from '@ionic/angular';
import { NotificationsService } from './service/notifications.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isLoggedIn: boolean = true;
  menuList: any;
  database: any;
  userDetails: any;
  profile: string;
  constructor(
    public serviceApi: ApiService,
    public authService: AuthenticationService,
    public pushNotifications: NotificationsService,
    public storageService: StorageService,
    private platform: Platform,
    

    public alertController: AlertController,

  ) {
    this.pushNotifications.initPush();
    this.logged();
    this.initializeApp();

    //this.getMessagingToken();
  }

  async logged() {


    this.authService.isLoggedIn().subscribe(val =>
      //console.log(val)
      this.isLoggedIn = val
    )



  }
  initializeApp() {
    this.platform.ready().then(() => {
    });

    // this.isLoggedIn =  this.logado();



  }
  

}
