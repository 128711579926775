import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { NotificationsService } from './notifications.service';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
export interface User {
  name: string;
  role: string;
  permissions: string[];
}
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  subscription: Subscription;
  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  //private profileUser: BehaviorSubject<any> = new BehaviorSubject(null);
public profileUser : any;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  profileRole: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  //profileUser : any;
  constructor(
    private http: HttpClient,
    public storageService: StorageService,
    private notification: NotificationsService,
    private router: Router
  ) {
    this.loadUser();
  }



  async loadUser() {

    this.profileUser =  await this.storageService.getObject(environment.name);

  
    if (this.profileUser) {

      this.isAuthenticated.next(true);
      this.currentUser.next(this.profileUser);
      if (Capacitor.getPlatform() !== 'web') {
        this.notification.registerTopic(this.profileUser.profile);
      } else {
        //  this.registerFire();
      }
    } else {
      this.isAuthenticated.next(false);
      this.currentUser.next(false);
    }
  }
  async setLogin(item) {
 
    const setLogin = await this.storageService.setObject(environment.name, item);
    this.profileUser = await this.storageService.getObject(environment.name);
    console.log(this.profileUser);
    
    this.isAuthenticated.next(true);
  
    
    this.currentUser.next(this.profileUser);
  
    

    this.router.navigateByUrl('/home', { replaceUrl: true });

    if (Capacitor.getPlatform() !== 'web') {
      this.notification.registerTopic(this.profileUser.profile);
    } else {
      //  this.registerFire();
    }
  }
  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

async getProfile(){
 // console.log(this.profileUser);
  return  this.profileUser;
  
}
  getUser() {
    return this.currentUser.asObservable();
  }

  hasPermission(permissions: string[]): boolean {
   
    // console.log("Current User: ",this.currentUser.value.profile);
    
  //  for (let permission = 0; permission <= permissions.length; permission++) {

      if (permissions == this.currentUser.value.profile) {
        return true
      } else {
        console.log("Sem acesso");
         return false;
      }
   // }
  }

  async logout() {

    const user = await this.storageService.getObject(environment.name);
    this.isAuthenticated.next(false);
    this.storageService.remove(environment.name);
    this.profileUser = null;
    //this.notification.UnregisterTopic(user.profile);
    this.storageService.remove('token');
  }
}
