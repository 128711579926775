import { FeedPageModule } from './pages/feed/feed/feed.module';
import { FeedPage } from './pages/feed/feed/feed.page';


import { AuthGuard } from './guards/auth.guard';

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


import { redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  
  {
		path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]

	},
  {
    path: 'privacity',
    loadChildren: () => import('./pages/privacity/privacity.module').then( m => m.PrivacityPageModule)
  },
  {
    path: 'projecao',
    loadChildren: () => import('./pages/projection/projection.module').then( m => m.ProjectionPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
 


  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfileModule),
    canActivate: [AuthGuard],
  
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'records',
    loadChildren: () => import('./pages/records/records.module').then( m => m.RecordsModule),
    canActivate: [AuthGuard],
  
  },
  {
    path: 'benchmark',
    loadChildren: () => import('./pages/records/benchmark/benchmark.module').then( m => m.BenchmarkPageModule),
    canActivate: [AuthGuard],
  
  },

   {
    path: 'check-in',
    loadChildren: () => import('./pages/check-in/check-in.module').then( m => m.CheckInModule),
    canActivate: [AuthGuard],
  
  },
  {
    path: 'adm',
    loadChildren: () => import('./pages/adm/adm.module').then( m => m.AdmModule),
    canActivate: [AuthGuard],
    data: {
      profile:  'STAFF',
    }
  
  },
  {
    path: 'feed',
    loadChildren: () => import('./pages/feed/feed.module').then( m => m.FeedModule),
    canActivate: [AuthGuard],
  
  },
 


  {
    path: 'register',
    loadChildren: () => import('./pages/profile/register/register.module').then( m => m.RegisterPageModule)
  },



  {
    path: 'addwod',
    loadChildren: () => import('./pages/wod/add-wod/add-wod.module').then( m => m.AddWodPageModule)
  },
  


  {
    path: 'wod',
    loadChildren: () => import('./pages/wod/wod/wod.module').then( m => m.WodPageModule)
  },
  
  {
    path: 'wod/:id',
    loadChildren: () => import('./pages/wod/wod-detail/wod-detail.module').then( m => m.WodDetailPageModule)
  },
  
  {
    path: 'wodScore/:id',
    loadChildren: () => import('./pages/wod/wodadd-score/wodadd-score.module').then( m => m.WodaddScorePageModule)
  },

  {
    path: 'cart',
    loadChildren: () => import('./pages/store/cart/cart.module').then( m => m.CartPageModule)
  },

   {
    path: 'experimental',
    loadChildren: () => import('./pages/extras/experimental/experimental.module').then( m => m.ExperimentalPageModule)
  },

  {
    path: 'sunday',
    loadChildren: () => import('./pages/extras/sunday/sunday.module').then( m => m.SundayPageModule)
  },


  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  }
,
  {
		path: '**',
		redirectTo: 'home',
		pathMatch: 'full'
	},
  {
    path: 'detail',
    loadChildren: () => import('./pages/wod/detail/detail.module').then( m => m.DetailPageModule)
  },



  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
