import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loading: Boolean;

  constructor( public loadingController: LoadingController,

    ) { }

    async presentLoader() {
    
      const loader ='<div class="loading-wrapper"><img src="/assets/imgs/logomarca.svg"><div  class="text-loading">Carregando</div></div>';
      const loading = await this.loadingController.create({     
        spinner: null,
        backdropDismiss: true,
      //  duration: 1000,
        animated: true,
       message: loader,
        translucent: true,
        cssClass: 'custom-loading'
      });
    return await loading.present();
    }
    async hideLoader() { 


      let topLoader = await this.loadingController.getTop();

      
      while (topLoader) {
        if (!(await topLoader.dismiss())) {
          // throw new Error('Could not dismiss the topmost loader. Aborting...');
          break
        }
        topLoader = await this.loadingController.getTop();
      }
    } 

}
