import { ExperimentalPage } from 'src/app/pages/extras/experimental/experimental.page';
import { ApiService } from './../../service/api.service';
import { StorageService } from './../../service/storage.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Router } from '@angular/router';
import { AuthLoginService } from 'src/app/service/auth-login.service';
import { RegisterPage } from '../adm/customers/register/register.page';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  user: any;
  menuList: any;
  name: any;

  experiment :string = 'experimental()'
  constructor(
    private storageService: StorageService,
    public modalController: ModalController,
    public authService: ApiService,
    public serviceLogin: AuthLoginService,
    private auth: AuthenticationService,
    private router: Router,
  ) {}

  async ngOnInit() {
    // this.Menu();
    const user = await this.storageService.getObject('kam');
    this.user = user;

    this.starMenu(user);
  }

  starMenu(dados: any) {
    console.log(dados);
    
    //   console.log(this.userDetails);
    this.menuList = null;

    
    this.authService.postTenant(dados, 'menuApp').then(
      (result) => {
        this.menuList = result;
        
        //console.log(this.menuList);
      },
      (err) => {}
    );
  }
  async openModal(item:string) {
    let itemComponent: any; // Declarando a variável antes do if

    if(item === 'experimental'){
       itemComponent = ExperimentalPage
    }else if (item === 'register'){
      itemComponent = RegisterPage
    }
    const modal = await this.modalController.create({
      component: itemComponent,
      cssClass: 'detail-modal',
      breakpoints: [0, 0.4, 1],
      initialBreakpoint: 1,
      
      componentProps: {},
    });
    return await modal.present();
  }



  async closeModal() {
    this.modalController.dismiss();
  }
  async logout() {
    await this.auth.logout();
    await this.serviceLogin.logout();
    this.closeModal();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }
}
