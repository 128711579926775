
import { LOCALE_ID, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { } from '@angular/core';



import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { FooterComponent } from './component/footer/footer.component';


import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { BrMaskerModule } from 'br-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Chart } from 'chart.js';

import { GooglePlus } from '@ionic-native/google-plus/ngx';

import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';

import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { JwtModule } from "@auth0/angular-jwt";

import {IonicInputMaskModule} from "@thiagoprz/ionic-input-mask";

import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';


@NgModule({
  declarations: [AppComponent, FooterComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    IonicInputMaskModule,
    BrMaskerModule,
    MaterialModule,
    
    //CartPageRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    //provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    //	provideAuth(() => getAuth()),
    IonicStorageModule.forRoot({}),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    JwtModule,
    AppRoutingModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production,
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
  ],
  providers: [
    // Add the Class to the providers
    GooglePlus,SignInWithApple,
    File,
    FileOpener,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {}
