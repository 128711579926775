

import { Component, OnInit, } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/service/api.service';
import { StorageService } from 'src/app/service/storage.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { AuthLoginService } from 'src/app/service/auth-login.service';
//import { CpfValidatePipe } from 'src/app/pipe/cpf/cpf-validate.pipe';


@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
  // cpfValidatePipe = new CpfValidatePipe();
  register: FormGroup;
  show = false;

  submitted = false;
  submitCPF = false;
  hide = true;

  responseData: any;
  firebase = { email: '', password: '' };
  dados: any;

  address = { cep: null, endereco: null, cidade: null, bairro: null,uf: null  };
  constructor(
    private modalController: ModalController,
    public storageService: StorageService,
    public formBuilder: FormBuilder,
    private navParams: NavParams,
    public serviceLogin: AuthLoginService,
    public authService: ApiService,
    public auth: AuthenticationService,
    public alertController: AlertController
  ) {
    // 

  }

  async ngOnInit() {

    this.register = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern('[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}')]],
      cpf: ['', [Validators.required, Validators.pattern('[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}')]],
      dataNascimento: ['',],
      uuid: [''],
      token: ['']

    });


    if (this.navParams.data.item) {
      const token = await this.storageService.getObject('token') ? await this.storageService.getObject('token') : '';
      setTimeout(() => {
        this.show = true;
        const user = this.navParams.data.item;


        this.register.get('name').setValue(user.name);
        this.register.get('email').setValue(user.email);
        this.register.get('uuid').setValue(user.uuid);
        this.register.get('token').setValue(token);
      }, 300);

    } else {
      // this.user = null;
    }
  }

  validateCpf() {
    var Soma = 0;

    const cpf = this.register.get('cpf').value;
    var strCPF = cpf.replace('.', '').replace('.', '').replace('-', '');
    //console.log(strCPF);

    if (
      strCPF === '00000000000' ||
      strCPF === '11111111111' ||
      strCPF === '22222222222' ||
      strCPF === '33333333333' ||
      strCPF === '44444444444' ||
      strCPF === '55555555555' ||
      strCPF === '66666666666' ||
      strCPF === '77777777777' ||
      strCPF === '88888888888' ||
      strCPF === '99999999999' ||
      strCPF.length !== 11) {
      this.register.get('cpf').setErrors({ invalidCpf: true });
    } else {

      for (let i = 1; i <= 9; i++) {
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      }
      var Resto = (Soma * 10) % 11;
      console.log(Resto);



      if ((Resto === 10) || (Resto === 11)) {
        Resto = 0;
      }
      Soma = 0;
      for (let k = 1; k <= 10; k++) {
        Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k)
      }

      Resto = (Soma * 10) % 11;
      if ((Resto === 10) || (Resto === 11)) {
        Resto = 0;
      }
      console.log(Resto);

      if (Resto !== parseInt(strCPF.substring(10, 11))) {
        this.register.get('cpf').setErrors({ invalidCpf: true });

      } else {
        this.register.get('cpf').setErrors(null)
      }
    }






    //     const cpfRegEx = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/;
    //     console.log(cpf);





  }

  calculateCpfDigit(cpfSplited) {
    let sum = 0;
    let multiplication = 10;
    for (let i = 0; i < 9; i++) {
      sum += cpfSplited[i] * multiplication;
      multiplication--;
    }
    let rest = sum % 11;
    let digit = 11 - rest;
    if (rest < 2) {
      return 0;
    }
    return digit;
  }



  getCEP() {
    this.authService.getCEP(this.address.cep).then(
      (result) => {
        this.responseData = result;

        this.address.endereco = this.responseData.logradouro;
        this.address.bairro = this.responseData.bairro;
        this.address.cidade = this.responseData.localidade;
        this.address.uf = this.responseData.uf;
      },
      (err) => { }
    );
  }


  get errorCtr() {
    return this.register.controls;
  }
  async closeModal() {
    const onClosedData: string = 'Wrapped Up!';
    await this.modalController.dismiss(onClosedData);
  }


  onSubmit() {
    this.submitted = true;
    if (this.register.valid) {
      this.gravar(this.register.value);
    }
  }

  async gravar(dados: any) {
    this.dados = dados;
    console.log(this.dados);

    this.authService.postTenant(this.dados, 'person').then(
      (result) => {
        this.responseData = result;
        
        if (this.responseData.statusCode === 400) {

        } else if (this.responseData.statusCode === 500) {
          this.AlertRegister(this.responseData.message);

        } else {
          this.AlertRegister("Cadastro Efetuado");
        }
      },
      (err) => {
        console.log(err);

        //this.AlertRegister(err);
      }
    );
  }


  async AlertRegister(texto = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Alerta!',
      message: texto,
      buttons: [
        {
          text: 'ok',
          handler: () => { },
        },
      ],
    });
    await alert.present();
  }

}
